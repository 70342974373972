import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reception-seating-plan',
  templateUrl: './reception-seating-plan.component.html',
  styleUrls: ['./reception-seating-plan.component.css']
})
export class ReceptionSeatingPlanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
