<div class="container">

    <div class="starter row">
        <h3>Starter</h3>
        <div class="row justify-content-md-center">
            <p> Ham Hock and Wholegrain Mustard Terrine - Piccalilli - Mizuna Cress</p>
            <p> Vegetarian:  Leek and Potato Soup - Chive Oil</p>
            <p> Children: Garlic Bread</p>
        </div>
    </div>

    <div class="mains row">
        <h3>Main</h3>
        <div class="row justify-content-md-center">
            <p> Roasted Local Chicken Breast - Smoked Bacon, Baby Onion, Wild Mushroom,
                Fondant Potato, Charred Leeks, Roasted Jus</p>
            <p> Vegetarian:  Beetroot, Wild Mushrooms & Goats Cheese Wellington Creamed Potatoes, Tender Stem Broccol</p>
            <p> Children:  Chicken Goujons & Hand Cut Chips with Garden Peas</p>
        </div>
    </div>

    <div class="desert row">
        <h3>Desert</h3>
        <div class="row justify-content-md-center">
            <p> Traditional Bakewell Tart - Raspberry Sorbert, Fresh Raspberries</p>
            <p> Children: Chocolate Brownie Sundae with Chocolate Ice Cream & Marshmallows</p>
        </div>
    </div>

</div>
