<div class="container">
    <h2>Plan For The Day</h2>
    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              Wedding Ceremony 14:00
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <strong>The wedding ceremony will be in 2 parts.  The first part will be undertaken by the Registrar to make the marrige legal.  The 2nd part will be done by Rev Brian Dingwall 
                  where we will exchange our vows.
              </strong> 
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Photographs  15:00
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <strong>At this point we will be doing the traditional photographs.  There will be a glass of prosecco for guests but no nibbles at this point so we recommend that people eat before ceremony </strong> .
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Meal 16:00
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <strong>The meal will be a traditional 3 course wedding meal with speaches to follow.  if you have any special dietary requirements please let us know</strong> 
          </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Evening Reception 19:00
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <strong>The evening reception when the rest of the guest arrive for disco and dancing.  We will be greeting our guests at 19:00 to 19:30.  The first dance and cutting of the cake will then follow
                    An evening buffet will be served at around 20:00 please let us know if you have any dietary requirments.
                </strong> 
              </div>
            </div>
          </div>
      </div>
</div>