<div class="container">
    <div >
        <h4> Tell us if you are coming</h4>
    </div>
    <div>
       <form [formGroup]="attendanceForm" (ngSubmit)=onSubmit()>
           <div class="form-group">
            <label for="name">Name: </label><br>
            <input id="name" type="text" formControlName="name">
           </div>
            
            <p>Please tell us if you are coming:</p>
              <input type="radio" id="yesResponse" formControlName="comingOrNot" value=true>
              <label for="yesResponse">Absoloutley Yes</label><br>
              <input type="radio" id="noResponse" formControlName="comingOrNot" value=false>
              <label for="noResponse">Sadly No</label><br>

            <label for="dietRequirments">Dietary Requirements: </label><br>
            <input type="text" id="dietRequirements" formControlName="dietaryRequirements"> <br>
            <button type="submit" [disabled]="!attendanceForm.valid">Submit</button>
              
       </form>
    </div>
</div>
