import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reception-menu',
  templateUrl: './reception-menu.component.html',
  styleUrls: ['./reception-menu.component.css']
})
export class ReceptionMenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
